'use strict';

angular.module('webPartnerPortalApp')
  .config(function ($routeProvider) {
    $routeProvider
      .when('/partner/:id', {
        templateUrl: 'app/partner/partner.html',
        controller: 'PartnerCtrl',
        controllerAs: 'PartnerCtrlVM',
        resolve: {
          partner: function ($http, $route) {
            return $http.get('api/partners/id/' + $route.current.params.id + '?field_group=CB');
          },
          labels: function ($http) {
            return $http.get('api/cobranding/labels/');
          },
          channels: function ($http) {
            return $http.get('api/partners/channels/');
          }
        }
      })
      .when('/partners', {
        templateUrl: 'app/partner/partners.html',
        controller: 'PartnersCtrl',
        controllerAs: 'PartnersCtrlVM',
        resolve: {
          partners: function ($http) {
            return $http.get('/api/partners?offset=0&limit=100');
          }
        }
      });
  });
